import React, { useEffect, useState } from "react";
import "../Dashboard/dashboard.css";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SideBar from "../sidebar/sidebar";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Input } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

const Vouchers = ({ setLoggedIn }) => {
  const schema = yup.object().shape({
    voucherCode: yup.string().required(),
    validity: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      voucherCode: "",
      validity: "", // Set an initial value for the voucher code
    },
  });

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const [vouchers, setVouchers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(15);
  const [showAddAdmin, setAddAdminShow] = useState(false);

  const fetchVouchersData = async () => {
    try {
      const response = await fetch(
        "https://api.thecarepro.co.uk/api/v1/voucher",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setVouchers(data?.payload);
      } else {
        console.error(
          "Failed to fetch Voucher data:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error);
    }
  };
  const onSubmitHandler = async (data) => {
    const userIdByStorage = localStorage.getItem("userId");

    let voucherObj = {
      voucherCode: data.voucherCode,
      validity: data.validity,
      adminId: userIdByStorage,
    };

    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/voucher";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(voucherObj),
      };
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        toast.success("Voucher added successfully");
        handleAddAdminClose();
        fetchVouchersData();
      } else {
        const errorMessage = "Failed to add voucher! Try again";
        toast.error(errorMessage);
        handleAddAdminClose();
        window.location.reload();
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const handleAddAdminClose = () => {
    setAddAdminShow(!showAddAdmin);
  };
  const handleAddAdminShow = () => {
    setAddAdminShow(!showAddAdmin);
    reset();
  };

  useEffect(() => {
    fetchVouchersData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountRole");

    setLoggedIn(false);
    navigate("/");
  };

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = vouchers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="dashboard-container">
      <SideBar className="sidebar"></SideBar>
      <div className="main-content p-5">
        <div className="header">
          <h2>
            <span>
              <i className="fa fa-ticket fa-1x"></i>
            </span>{" "}
            Vouchers
          </h2>
          <button className="logout-button" onClick={handleLogout}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSignOutAlt style={{ marginLeft: "5px" }} />
            </div>
          </button>
        </div>
        <div className="row mt-5">
          <div className="col-md-10"></div>
          <div className="col-md-2 d-flex justify-content-end gap">
            <button
              className="btn btn-sm purple text-white"
              onClick={handleAddAdminShow}
            >
              Add Voucher
            </button>
            <Modal show={showAddAdmin} onHide={handleAddAdminClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Your Voucher Code</Modal.Title>
              </Modal.Header>
              <form
                className="sign-in-form2"
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <Modal.Body>
                  <div className="row p-3">
                    <div className="col-md-6 mt-3">
                      <Controller
                        control={control}
                        name="voucherCode"
                        render={({ field }) => (
                          <Input
                            {...field}
                            invalid={!!errors.voucherCode?.message}
                            placeholder="Enter your Voucher Code"
                          />
                        )}
                      />
                      <div
                        style={{
                          height: "20px",
                          color: "red",
                          padding: "4px 2px",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.voucherCode?.message}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Controller
                        control={control}
                        name="validity"
                        render={({ field }) => (
                          <FormControl
                            variant="standard"
                            fullWidth
                            className="w-100"
                          >
                            <InputLabel id="validity-label">
                              Validity
                            </InputLabel>
                            <Select
                              labelId="validity-label"
                              {...field}
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                            >
                              <MenuItem value="1day">1 Day</MenuItem>
                              <MenuItem value="1week">1 Week</MenuItem>
                              <MenuItem value="1month">1 Month</MenuItem>
                              <MenuItem value="3months">3 Months</MenuItem>
                              <MenuItem value="6months">6 Months</MenuItem>
                              <MenuItem value="1year">1 Year</MenuItem>
                              {/* Add more options as needed */}
                            </Select>
                          </FormControl>
                        )}
                      />

                      <div
                        style={{
                          height: "20px",
                          color: "red",
                          padding: "4px 2px",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.Validity?.message}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="purple-outline"
                    onClick={handleAddAdminClose}
                  >
                    Cancel
                  </Button>
                  <Button className="purple text-white" type="submit">
                    Add
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
        <table>
          <div className="row mt-2">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Voucher Code</th>
                  <th>Validity</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers && currentUsers.length > 0 ? (
                  currentUsers.map((item, index) => (
                    <tr key={index}>
                      <td>{item._id}</td>
                      <td>{item.voucherCode}</td>
                      <td>{item.validity}</td>
                      <td>{new Date(item.dateCreated).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr key="no-data">
                    <td colSpan="4">No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </table>
        {/* Pagination */}
        <div className="text-muted mt-2" style={{ float: "left" }}>
          Showing {indexOfFirstUser + 1} to{" "}
          {Math.min(indexOfLastUser, vouchers.length)} of {vouchers.length}{" "}
          results
        </div>
        <div className="d-flex justify-content-end mt-2">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
              {Array.from({
                length: Math.ceil(vouchers.length / usersPerPage),
              }).map((_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === Math.ceil(vouchers.length / usersPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        {/* Displaying current range of data */}
      </div>
    </div>
  );
};

export default Vouchers;
