import React, { useEffect, useState } from "react";
import "../Dashboard/dashboard.css";
import { FaSignOutAlt, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SideBar from "../sidebar/sidebar";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input } from "@mui/material";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Admin = ({ setLoggedIn }) => {
  //VOUCHER WORK
  const schema = yup.object().shape({
    emailId: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      emailId: "",
    },
  });

  const token = localStorage.getItem("token");

  const fetchAdminData = async () => {
    try {
      const response = await fetch(
        "https://api.thecarepro.co.uk/api/v1/super-admin/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setAdminData(data?.payload);
      } else {
        console.error(
          "Failed to fetch Admin Data data:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error);
    }
  };

  const onSubmitHandler = async (data) => {
    console.log('data :>> ', data);
    let adminObj = {
      email: data.emailId,
    };

    console.log("adminObj :>> ", adminObj);
    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/super-admin/byemail";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(adminObj),
      };
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        toast.success("Admin added successfully");
        handleClose();
        fetchAdminData();
      } else {
        const errorMessage = "Failed to add Admin! Try again";
        toast.error(errorMessage);
        handleClose();
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(!show);
  };
  const handleShow = () => {
    setShow(!show);
    reset();
  };
  
  const handleDeleteAdmin = async (adminEmail) => {
    try {
        const apiUrl = `https://api.thecarepro.co.uk/api/v1/super-admin/byemail`;
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email: adminEmail }), // Include email in the request body
        };
        const response = await fetch(apiUrl, requestOptions);
        if (response.ok) {
          toast.success("Admin deleted successfully");
          fetchAdminData();
        } else {
          const errorMessage = "Failed to delete Admin! Try again";
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again later.");
      }
  };
  //DASHBOARD WORK
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const [adminData, setAdminData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(15);

  useEffect(() => {
    fetchAdminData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountRole");
    setLoggedIn(false);
    navigate("/");
  };

   // Get current users
   const indexOfLastUser = currentPage * usersPerPage;
   const indexOfFirstUser = indexOfLastUser - usersPerPage;
   const currentUsers = adminData.slice(indexOfFirstUser, indexOfLastUser);
 
   // Change page
   const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="dashboard-container">
      <SideBar className="sidebar"></SideBar>
      <div className="main-content p-5">
        <div className="header mt-2">
          <h2>
            <span>
              <i className="fa fa-home fa-1x"></i>
            </span>{" "}
            Admins
          </h2>
          <button className="logout-button" onClick={handleLogout}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSignOutAlt style={{ marginLeft: "5px" }} />
            </div>
          </button>
        </div>

        {/* <p>SUPER ADMIN!</p> */}

        <div className="row mt-5">
          <div className="col-md-10"></div>
          <div className="col-md-2 d-flex justify-content-end gap">
            <button
              className="btn btn-sm purple text-white"
              onClick={handleShow}
            >
              Add Admin
            </button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Admin</Modal.Title>
              </Modal.Header>
              <form
                className="sign-in-form2"
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <Modal.Body>
                  <div className="row p-3">
                    <div className="col-md-10">
                      <Controller
                        control={control}
                        name="emailId"
                        render={({ field }) => (
                          <Input
                            {...field}
                            invalid={!!errors.emailId?.message}
                            placeholder="Enter Email"
                          />
                        )}
                      />
                      <div
                        style={{
                          height: "20px",
                          color: "red",
                          padding: "4px 2px",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.emailId?.message}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="purple-outline" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className="purple text-white" type="submit">
                    Add
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>

          <div className="col-md-12 col-lg-12 col-xl-12 mt-2">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Email Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {adminData && adminData.length > 0 ? (
                  adminData.slice(0, 12).map((item,index) => (
                    <tr key={index}>
                      <td>{item._id}</td>
                      <td>{item.username}</td>
                      <td>{item.email}</td>
                      <td> {/* Action column */}
                        <FaTrash
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteAdmin(item.email)}
                        />
                      </td>
                      {/* <td>{new Date(item.createdAt).toLocaleString()}</td> */}
                    </tr>
                  ))
                ) : (
                  <p style={{ display: "flex" }}>No Data Found</p>
                )}
              </tbody>
            </table>
            {/* Pagination */}
        <div className="text-muted mt-2" style={{ float: "left" }}>
          Showing {indexOfFirstUser + 1} to{" "}
          {Math.min(indexOfLastUser, adminData.length)} of {adminData.length}{" "}
          results
        </div>
        <div className="d-flex justify-content-end mt-2">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
              {Array.from({
                length: Math.ceil(adminData.length / usersPerPage),
              }).map((_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === Math.ceil(adminData.length / usersPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        {/* Displaying current range of data */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
