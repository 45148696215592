import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { FaSignOutAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import socialCareLogoNew from "../../thecareprocouk-high-resolution-logo-transparent (1).png";

const SideBar = ({ setLoggedIn }) => {
  const role = localStorage.getItem("accountRole");
  console.log('role :>> ', role);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const [activeButton, setActiveButton] = useState("/");
  const [showAdmin, setShowAdmin] = useState(false);

  const handleButtonClick = (path) => {
    setActiveButton(path);
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountRole");

    setLoggedIn(false);
    navigate("/");
  };

  useEffect(() => {
    // Update active button when location changes
    setActiveButton(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className={`bar ${isSidebarOpen ? "open" : ""}`}>
        <h2
          style={{
            padding: "16px 12px",
            color: "white",
            fontWeight: "bold",
          }}
        >
          <div className="logo-container">
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
              to="/"
            >
              <img src={socialCareLogoNew} alt="Logo" className="logo" />
            </Link>
          </div>
        </h2>

        <div className="p-2">
          <button
            className={`d-flex justify-content-evenly ${
              activeButton === "/dashboard" ? "purple" : "grey"
            } text-white w-100`}
            onClick={() => handleButtonClick("/")}
          >
            <i className="fa fa-home"></i>
            <span className="fs-6">Dashboard</span>
          </button>
          <button
            className={`d-flex justify-content-evenly ${
              activeButton === "/free-users" ? "purple" : "grey"
            } text-white w-100`}
            onClick={() => handleButtonClick("/free-users")}
          >
            <i className="fa-user fa-solid"></i>
            <span className="fs-6">Free Users</span>
          </button>
          <button
            className={`d-flex justify-content-evenly ${
              activeButton === "/paid-users" ? "purple" : "grey"
            } text-white w-100`}
            onClick={() => handleButtonClick("/paid-users")}
          >
            <i className="fa-user fa-solid"></i>
            <span className="fs-6">Paid Users</span>
          </button>
          <button
            className={`d-flex justify-content-evenly ${
              activeButton === "/vouchers" ? "purple" : "grey"
            } text-white w-100`}
            onClick={() => handleButtonClick("/vouchers")}
          >
            <i className="fa-solid fa-ticket"></i>
            <span className="fs-6">Vouchers</span>
          </button>
          <button
            className={`d-flex justify-content-evenly ${
              activeButton === "/stripe-payments" ? "purple" : "grey"
            } text-white w-100`}
            onClick={() => handleButtonClick("/stripe-payments")}
          >
            <i className="fa-solid fa-money-bill"></i>
            <span className="fs-6">Stripe Payments</span>
          </button>

          {/* SUPER ADMIN SIDEBAR */}
          {role !== "admin" && (
            <button
              className={`d-flex justify-content-evenly ${
                activeButton === "/admins" ? "purple" : "grey"
              } text-white w-100`}
              onClick={() => handleButtonClick("/admins")}
            >
              <i className="fa fa-home"></i>
              <span className="fs-6">View Admin</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
