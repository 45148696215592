// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  width: 15%;
  background: #000;
  /* height: 100%; */
  overflow: auto;
  z-index: 8;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.logo {
  height: auto;
  width: 200px;
}

#logout_sidebar_button {
  position: absolute;
  display: inline-block;
  bottom: 100px;
  /* left: 15px; */
  width: 14%;
}
.black {
  background-color: #000;
}
.black:hover {
  background-color: purple;
}

.new-chat-black {
  width: 100%;
  padding: 15px;
  margin-left: 10px;
  height: 20ox;
  display: flex;
  align-content: space-around;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;
  border: 15px;
}

.grey {
  background-color: #333333;
}

.grey:hover {
  background-color: purple;
}

.purple{
  background: linear-gradient(180deg, rgba(173,5,201,1) 0%, rgba(59,22,87,1) 59%, rgba(0,0,0,1) 100%);
}
`, "",{"version":3,"sources":["webpack://./src/Components/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,UAAU;AACZ;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,aAAa;EACb,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,sBAAsB;AACxB;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,2BAA2B;EAC3B,eAAe;EACf,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mGAAmG;AACrG","sourcesContent":[".bar {\n  width: 15%;\n  background: #000;\n  /* height: 100%; */\n  overflow: auto;\n  z-index: 8;\n}\n.logo-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n}\n\n.logo {\n  height: auto;\n  width: 200px;\n}\n\n#logout_sidebar_button {\n  position: absolute;\n  display: inline-block;\n  bottom: 100px;\n  /* left: 15px; */\n  width: 14%;\n}\n.black {\n  background-color: #000;\n}\n.black:hover {\n  background-color: purple;\n}\n\n.new-chat-black {\n  width: 100%;\n  padding: 15px;\n  margin-left: 10px;\n  height: 20ox;\n  display: flex;\n  align-content: space-around;\n  justify-content: flex-start;\n  cursor: pointer;\n  align-items: center;\n  border: 15px;\n}\n\n.grey {\n  background-color: #333333;\n}\n\n.grey:hover {\n  background-color: purple;\n}\n\n.purple{\n  background: linear-gradient(180deg, rgba(173,5,201,1) 0%, rgba(59,22,87,1) 59%, rgba(0,0,0,1) 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
