import React, { useEffect, useState } from "react";
import "../Dashboard/dashboard.css";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import socialCareLogoNew from "../../thecareprocouk-high-resolution-logo-transparent (1).png";
import SideBar from "../sidebar/sidebar";

const FreeUsers = ({ setLoggedIn }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const [freeUsers, setFreeUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(15);

  useEffect(() => {
    const fetchFreeUsersData = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/freeusers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setFreeUsers(data?.payload);
        } else {
          console.error(
            "Failed to fetch Stripe data:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };

    fetchFreeUsersData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountRole");

    setLoggedIn(false);
    navigate("/");
  };

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = freeUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="dashboard-container">
      <SideBar className="sidebar"></SideBar>
      <div className="main-content p-5">
        <div className="header">
          <h2>
            <span>
              <i className="fa fa-user fa-1x"></i>
            </span>{" "}
            Free Users
          </h2>
          <button className="logout-button" onClick={handleLogout}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSignOutAlt style={{ marginLeft: "5px" }} />
            </div>
          </button>
        </div>
        <table>
          <div className="row mt-2">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Last Login</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers && currentUsers.length > 0 ? (
                  currentUsers.map((item) => (
                    <tr key={item.customerId}>
                      <td>{item._id}</td>
                      <td>{item.username}</td>
                      <td>{item.email}</td>
                      <td>{new Date(item.lastLogin).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </table>
        {/* Pagination */}
        <div className="text-muted mt-2" style={{float:"left"}}>
          Showing {indexOfFirstUser + 1} to {Math.min(indexOfLastUser, freeUsers.length)} of {freeUsers.length} results
        </div>
        <div className="d-flex justify-content-end mt-2">
        <nav aria-label="Page navigation">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </button>
            </li>
            {Array.from({
              length: Math.ceil(freeUsers.length / usersPerPage),
            }).map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(freeUsers.length / usersPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </nav>
        </div>
      </div>
    </div>
  );
};

export default FreeUsers;
