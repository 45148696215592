import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

const Login = ({ setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch('https://api.thecarepro.co.uk/api/v1/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: username, password: password }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.payload?.jwt) {
          localStorage.setItem('token', data?.payload?.jwt);
          localStorage.setItem('userId', data?.payload?.userId);
          localStorage.setItem('accountRole',data?.payload.role)
          setLoggedIn(true);
          localStorage.setItem('login', true);
          toast.success('Success');
          navigate('/dashboard');
        } else {
          toast.error('Invalid login or password');
        }
      } else {
        toast.error('Invalid login or password');
      }
    } catch (error) {
      toast.error('Error');
    }
  };

  return (
    <div className="login-container">
    <div className="card-0">
      <h2 className='login fs-1 text-white'>Login</h2>
      <form className='mt-5'>
        <div className="form-group">
          <input type="text" placeholder='Enter Email' value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="form-group">
          <input type="password" placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="button" className='purple' onClick={handleLogin}>
          Sign In
        </button>
      </form>
    </div>
  </div>
  );
};

export default Login;
