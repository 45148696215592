import React, { useEffect, useState } from "react";
import "../Dashboard/dashboard.css";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SideBar from "../sidebar/sidebar";

const StripePayments = ({ setLoggedIn }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [stripeData, setStripeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsPerPage] = useState(15);

  useEffect(() => {
    const fetchStripeData = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/stripeentities",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setStripeData(data?.payload);
        } else {
          console.error(
            "Failed to fetch Stripe data:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };

    fetchStripeData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountRole");

    setLoggedIn(false);
    navigate("/");
  };

  // Get current payments
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = stripeData.slice(indexOfFirstPayment, indexOfLastPayment);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="dashboard-container">
      <SideBar className="sidebar"></SideBar>
      <div className="main-content p-5">
        <div className="header">
          <h2>
            <span>
              <i className="fa fa-money-bill fa-1x"></i>
            </span>{" "}
            Stripe Payments
          </h2>
          <button className="logout-button" onClick={handleLogout}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSignOutAlt style={{ marginLeft: "5px" }} />
            </div>
          </button>
        </div>
        <table>
          <div className="row mt-2">
            <table>
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Credit</th>
                  <th>User ID</th>
                  <th>Email</th>
                  <th>Payed On</th>
                </tr>
              </thead>
              <tbody>
                {currentPayments && currentPayments.length > 0 ? (
                  currentPayments.map((item) => (
                    <tr key={item.customerId}>
                      <td>{item.customerId}</td>
                      <td>{item.price}</td>
                      <td>{item.userId}</td>
                      <td>{item.email}</td>
                      <td>{new Date(item.createdAt).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </table>
        {/* Pagination */}
        <div className="text-muted mt-2" style={{float:"left"}}>
          Showing {indexOfFirstPayment + 1} to {Math.min(indexOfLastPayment, stripeData.length)} of {stripeData.length} results
        </div>
        <div className="d-flex justify-content-end mt-2">
        <nav aria-label="Page navigation">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </button>
            </li>
            {Array.from({
              length: Math.ceil(stripeData.length / paymentsPerPage),
            }).map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === Math.ceil(stripeData.length / paymentsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </nav>
        </div>
      </div>
    </div>
  );
};

export default StripePayments;
