// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
  color: black;
}

.card-0 {
  padding: 60px;
  border-radius: 12px;
  background-color: #333;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.form-group {
  margin-bottom: 30px;
}

label {
  margin-bottom: 15px;
  font-size: 20px;
}

input {
  padding: 15px;
  margin-bottom: 20px;
  font-size: 18px;
}

button {
  padding: 20px;
  font-size: 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0b7dda;
}

.login-text {
  margin-top: 10px;
  font-size: 24px;
}

.fields {
  margin-right: 20px;
}

.btn {
  border-radius: 20px;
  width: 60%;
}

.login {
  color: rgb(146, 148, 150);
  margin-right: 20px;
  font-size: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".login-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: white;\n  color: black;\n}\n\n.card-0 {\n  padding: 60px;\n  border-radius: 12px;\n  background-color: #333;\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n  text-align: center;\n}\n\n.form-group {\n  margin-bottom: 30px;\n}\n\nlabel {\n  margin-bottom: 15px;\n  font-size: 20px;\n}\n\ninput {\n  padding: 15px;\n  margin-bottom: 20px;\n  font-size: 18px;\n}\n\nbutton {\n  padding: 20px;\n  font-size: 20px;\n  background-color: #2196F3;\n  color: white;\n  border: none;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #0b7dda;\n}\n\n.login-text {\n  margin-top: 10px;\n  font-size: 24px;\n}\n\n.fields {\n  margin-right: 20px;\n}\n\n.btn {\n  border-radius: 20px;\n  width: 60%;\n}\n\n.login {\n  color: rgb(146, 148, 150);\n  margin-right: 20px;\n  font-size: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
