import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import socialCareLogoNew from "../../thecareprocouk-high-resolution-logo-transparent (1).png";
import SideBar from "../sidebar/sidebar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dashboard1 from "../../Image.png";
import Dashboard2 from "../../Image2.png";
import Dashboard3 from "../../Image3.png";
import { Row, Col, Modal } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input } from "@mui/material";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Dashboard = ({ setLoggedIn }) => {
  //VOUCHER WORK
  const schema = yup.object().shape({
    voucherCode: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      voucherCode: "", // Set an initial value for the voucher code
    },
  });

  const [voucherApplied, setVoucherApplied] = useState(false);

  const onSubmitHandler = async (data) => {
    const userIdByStorage = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let voucherObj = {
      voucherCode: data.voucherCode,
      userId: userIdByStorage,
    };

    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/voucher/redeem";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(voucherObj),
      };
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        toast.success("Voucher added successfully");
        setVoucherApplied(true);
        handleClose();
      } else {
        const errorMessage = "Failed to add voucher! Try again";
        toast.error(errorMessage);
        handleClose();
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };
  const [show, setShow] = useState(false);
  const [showAddAdmin, setAddAdminShow] = useState(false);

  const handleClose = () => {
    setShow(!show);
  };
  const handleShow = () => setShow(!show);

  const handleAddAdminClose = () => {
    setAddAdminShow(!showAddAdmin);
  };
  const handleAddAdminShow = () => setAddAdminShow(!showAddAdmin);

  //DASHBOARD WORK
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const [stripeData, setStripeData] = useState([]);
  const [freeUsers, setFreeUsers] = useState([]);
  const [paidUsers, setPaidUsers] = useState([]);
  const [freeCount, setFreeCount] = useState([]);
  const [paidCount, setPaidCount] = useState([]);
  const [showAdmin, setShowAdmin] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchStripeData = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/stripeentities",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setStripeData(data?.payload);
        } else {
          console.error(
            "Failed to fetch Stripe data:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };

    const fetchFreeUsersData = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/freeusers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setFreeUsers(data?.payload);
        } else {
          console.error(
            "Failed to fetch Stripe data:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };

    const fetchPaidUsersData = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/paidusers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setPaidUsers(data?.payload);
        } else {
          console.error(
            "Failed to fetch Stripe data:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };
    const fetchFreeUsersCount = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/freecount",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setFreeCount(data?.payload);
        } else {
          console.error(
            "Failed to fetch Free Users Count:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };
    const fetchPaidUsersCount = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/paidcount",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setPaidCount(data?.payload);
        } else {
          console.error(
            "Failed to fetch Free Users Count:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };
    const fetchTotalRevenue = async () => {
      try {
        const response = await fetch(
          "https://api.thecarepro.co.uk/api/v1/admin/revenue",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setTotalRevenue(data?.payload);
        } else {
          console.error(
            "Failed to fetch Free Users Count:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred during the API request:", error);
      }
    };

    fetchTotalRevenue();
    fetchPaidUsersCount();
    fetchFreeUsersCount();
    fetchStripeData();
    fetchFreeUsersData();
    fetchPaidUsersData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("uid");
    localStorage.removeItem("accountRole");
    setLoggedIn(false);
    navigate("/");
  };

  return (
    <div className="dashboard-container">
      <SideBar className="sidebar"></SideBar>
      <div className="main-content p-5">
        <div className="header mt-2">
          <h2>
            <span>
              <i className="fa fa-home fa-1x"></i>
            </span>{" "}
            Dashboard
          </h2>
          <button className="logout-button" onClick={handleLogout}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSignOutAlt style={{ marginLeft: "5px" }} />
            </div>
          </button>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 col-lg-12 col-xl-8">
            <div className="row">
              <div className="col-md-4">
                <Card variant="outlined" sx={{ minWidth: 200, minHeight: 250 }}>
                  <CardContent>
                    <div className="d-flex">
                      <img alt="" className="img-fluid" src={Dashboard1}></img>
                    </div>
                  </CardContent>
                  <CardActions>
                    <div className="d-flex flex-column align-items-baseline mt-5 p-3">
                      <h5 className="fw-light">Total Free Users</h5>
                      <h5 className="fw-bolder">{freeCount}</h5>
                    </div>
                  </CardActions>
                </Card>
              </div>
              <div className="col-md-4">
                <Card variant="outlined" sx={{ minWidth: 200, minHeight: 250 }}>
                  <CardContent>
                    <div className="d-flex">
                      <img alt="" className="img-fluid" src={Dashboard2}></img>
                    </div>
                  </CardContent>
                  <CardActions>
                    <div className="d-flex flex-column align-items-baseline mt-5 p-3">
                      <h5 className="fw-light">Total Paid Users</h5>
                      <h5 className="fw-bolder">{paidCount}</h5>
                    </div>
                  </CardActions>
                </Card>
              </div>
              <div className="col-md-4">
                <Card variant="outlined" sx={{ minWidth: 200, minHeight: 250 }}>
                  <CardContent>
                    <div className="d-flex">
                      <img alt="" className="img-fluid" src={Dashboard3}></img>
                    </div>
                  </CardContent>
                  <CardActions>
                    <div className="d-flex flex-column align-items-baseline mt-5 p-3">
                      <h5 className="fw-light">Stripe Amount</h5>
                      <h5 className="fw-bolder">$ {totalRevenue}</h5>
                    </div>
                  </CardActions>
                </Card>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="d-flex mt-2">
                  <h4 className="fs-6 fw-bolder">Recent Free Users</h4>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Username</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {freeUsers && freeUsers.length > 0 ? (
                      freeUsers.slice(0, 5).map((item) => (
                        <tr key={item.customerId}>
                          <td>{item._id}</td>
                          <td>{item.username}</td>
                          <td>{item.email}</td>
                        </tr>
                      ))
                    ) : (
                      <p style={{ display: "flex" }}>No Data Found</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="d-flex mt-2">
                  <h4 className="fs-6 fw-bolder">Recent Paid Users</h4>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Username</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paidUsers && paidUsers.length > 0 ? (
                      paidUsers.slice(0, 5).map((item) => (
                        <tr key={item.customerId}>
                          <td>{item._id}</td>
                          <td>{item.username}</td>
                          <td>{item.email}</td>
                        </tr>
                      ))
                    ) : (
                      <p style={{ display: "flex" }}>No Data Found</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-xl-4">
            <div className="d-flex mt-2">
              <h4 className="fs-6 fw-bolder">Recent Stripe Payments</h4>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Credit</th>
                  <th>Paid On</th>
                </tr>
              </thead>
              <tbody>
                {stripeData && stripeData.length > 0 ? (
                  stripeData.slice(0, 12).map((item) => (
                    <tr key={item.customerId}>
                      <td>{item.customerId}</td>
                      <td>{item.price}</td>
                      <td>{new Date(item.createdAt).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <p style={{ display: "flex" }}>No Data Found</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
