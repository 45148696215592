import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FreeUsers from "./Components/Free-Users/FreeUsers";
import PaidUsers from "./Components/Paid-Users/paidUsers";
import StripePayments from "./Components/StripePayments/stripe-payments";
import Vouchers from "./Components/Vouchers/vouchers";
import Admin from "./Components/Admin/admin";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const isLogin = localStorage.getItem("login") === "true";
  const role = localStorage.getItem("accountRole");
  const [userActive, setUserActive] = useState(true); // Define userActive state variable

  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountRole");
    setLoggedIn(false);
    return <Navigate to="/" />;
  };

  useEffect(() => {
    console.log("Setting up logout timer...");
    let logoutTimer = setTimeout(() => {
      setUserActive(false);
      handleLogout();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        setUserActive(false);
        handleLogout();
      }, 10 * 60 * 1000); // 5 seconds in milliseconds
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(logoutTimer);
    };
  }, []);


  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              loggedIn || isLogin ? (
                <Navigate to="/dashboard" />
              ) : (
                <Login setLoggedIn={setLoggedIn} />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              loggedIn || isLogin ? (
                <Dashboard setLoggedIn={setLoggedIn} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/free-users"
            element={loggedIn || isLogin ? <FreeUsers setLoggedIn={setLoggedIn} /> : <Navigate to="/" />}
          />
          <Route
            path="/paid-users"
            element={loggedIn || isLogin ? <PaidUsers  setLoggedIn={setLoggedIn}/> : <Navigate to="/" />}
          />
          <Route
            path="/stripe-payments"
            element={
              loggedIn || isLogin ? <StripePayments setLoggedIn={setLoggedIn}/> : <Navigate to="/" />
            }
          />
          <Route
            path="/vouchers"
            element={
              loggedIn || isLogin ? <Vouchers setLoggedIn={setLoggedIn}/> : <Navigate to="/" />
            }
          />
          <Route
            path="/admins"
            element={
              (loggedIn || isLogin) && role !== "admin" ? (
                <Admin setLoggedIn={setLoggedIn} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
